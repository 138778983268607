import { useApolloClient } from '@apollo/client';
import type {
  TagSelectorQuery,
  TagSelectorQueryVariables,
  TopTagsQuery,
  TopTagsQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  ControlProps,
  MenuListProps,
  MenuProps,
  OnChangeValue,
  OptionProps,
  SelectInstance
} from 'react-select';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SharedComponent } from '../../../enums';
import Icons from '../../../icons';
import { IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import type { AppContextInterface } from '../../context/AppContext/AppContext';
import AppContext from '../../context/AppContext/AppContext';
import useTranslation from '../../useTranslation';
import topTagsQuery from './../TopTags.query.graphql';
import TagOption from './TagOption';
import localStyles from './TagSelector.module.pcss';
import tagSelectorQuery from './TagSelector.query.graphql';
import { useReactSelectTheme } from '../../../helpers/styles/ReactSelectThemeHelper';

interface Props {
  /**
   * @callback
   * A callback function called when a tag is saved.
   */
  onAdd: (tag: OnChangeValue<CreatableTagOption, boolean>, actionMeta: { action: string }) => void;

  /**
   * A callback function when the tag selector is blurred.
   *
   * @callback
   */
  onBlur?: () => void;

  /**
   * A callback function when the tag selector is focused.
   *
   * @callback
   */
  onFocus?: () => void;
  /**
   * The number of tags to return when searching.  Defaults to 5.
   */
  pageSize?: number;
  /**
   * Which query to use to fetch the tags
   */
  tagsQueryFetchType: TagsQueryFetchType;
  /**
   * List of preset tags for the message
   */
  presetTags?: TagOption[];
  /**
   * The node scope to select tags
   */
  nodeId?: string;
}

/**
 * Represents a creatable tag option used by react-select. This is an internal
 * property used by react-select that is currently not exposed by their TypeScript
 * definitions.
 */
export interface CreatableTagOption extends TagOption {
  /**
   * Whether the option represents the action to create a new item
   */
  __isNew__?: boolean;
}

/**
 * Enum to pass as prop so as to specify which query to use to fetch tags
 */
export enum TagsQueryFetchType {
  FETCH_PRESET_TAGS,
  FETCH_MATCHING_TAGS,
  FETCH_TOP_TAGS
}

/**
 * An selector for finding and adding tags to a message.
 *
 * @constructor
 *
 * @author Dolan Halbrook, Willi Hyde
 */
const TagSelector: React.FC<React.PropsWithChildren<Props>> = ({
  onAdd,
  onBlur,
  onFocus,
  pageSize = 5,
  tagsQueryFetchType,
  presetTags,
  nodeId
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.TAG_SELECTOR);
  const client = useApolloClient();
  const reference = useRef<SelectInstance<CreatableTagOption, boolean>>();
  const { contextNode } = useContext<AppContextInterface>(AppContext);
  const contextNodeId = nodeId === undefined ? contextNode.id : nodeId;

  const reactSelectTheme = useReactSelectTheme();

  const useUserDefinedTags = tagsQueryFetchType === TagsQueryFetchType.FETCH_MATCHING_TAGS;

  const Menu: React.FC<React.PropsWithChildren<MenuProps<CreatableTagOption, boolean>>> = props => {
    const { children } = props;
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.Menu {...props} className={cx('lia-menu')}>
        {children}
      </components.Menu>
    );
  };

  const MenuList: React.FC<
    React.PropsWithChildren<MenuListProps<CreatableTagOption, boolean>>
  > = props => {
    const { children, selectProps } = props;
    return (
      <components.MenuList
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={cx('lia-menu-list', {
          // eslint-disable-next-line no-underscore-dangle
          'lia-menu-list-has-create-option': (selectProps?.options[0] as CreatableTagOption)
            ?.__isNew__
        })}
      >
        {children}
      </components.MenuList>
    );
  };

  const Control: React.FC<
    React.PropsWithChildren<ControlProps<CreatableTagOption, boolean>>
  > = props => {
    const { children, isFocused } = props;
    return (
      <components.Control
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={cx('lia-control', {
          'lia-control-is-focused': isFocused
        })}
      >
        {children}
      </components.Control>
    );
  };

  const ValueContainer: React.FC<
    React.PropsWithChildren<ControlProps<CreatableTagOption, boolean>>
  > = props => {
    const { children } = props;
    return (
      <components.ValueContainer
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={cx('form-control form-control')}
      >
        {children}
      </components.ValueContainer>
    );
  };

  const Option: React.FC<
    React.PropsWithChildren<OptionProps<CreatableTagOption, boolean>>
  > = props => {
    const { children, data, isFocused } = props;
    return (
      <components.Option
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={cx(
          'lia-option',
          {
            // eslint-disable-next-line no-underscore-dangle
            'lia-option-is-create': data?.__isNew__
          },
          {
            'lia-option-is-focused': isFocused
          }
        )}
      >
        {children}
      </components.Option>
    );
  };

  /**
   * Callback used to fetch the tags list based on the input value
   */
  const fetchMatchingTags = useCallback(
    async (inputValue: string): Promise<TagOption[]> => {
      const { loading, data } = await client.query<TagSelectorQuery, TagSelectorQueryVariables>({
        query: tagSelectorQuery,
        variables: {
          first: pageSize,
          text: inputValue
        },
        fetchPolicy: 'no-cache'
      });
      return loading ? [] : data?.matchingTags?.map(tag => new TagOption(tag));
    },
    [client, pageSize]
  );

  /**
   * Callback used to fetch the tags list based on the input value within the scope of the context node
   */
  const fetchTopTags = useCallback(
    async (inputValue: string): Promise<TagOption[]> => {
      const { loading, data } = await client.query<TopTagsQuery, TopTagsQueryVariables>({
        query: topTagsQuery,
        variables: {
          id: contextNodeId,
          first: pageSize,
          constraints: {
            text: { eq: inputValue }
          },
          useTagLastActivityTime: false,
          useTagCounts: false
        },
        fetchPolicy: 'no-cache'
      });
      return loading
        ? []
        : data?.coreNode?.topTags.edges.map(tagNode => {
            return { label: tagNode.node.text, value: tagNode.node.id };
          });
    },
    [client, pageSize, contextNodeId]
  );

  /**
   * Callback used to fetch the preset tags
   */
  async function fetchPresetTags(inputValue: string): Promise<TagOption[]> {
    const presetTagsList = presetTags?.filter(tag =>
      tag.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return presetTagsList.sort((a, b) => a.label.localeCompare(b.label));
  }

  const getTagsFetchOptions = () => {
    switch (tagsQueryFetchType) {
      case TagsQueryFetchType.FETCH_PRESET_TAGS: {
        return fetchPresetTags;
      }
      case TagsQueryFetchType.FETCH_TOP_TAGS: {
        return fetchTopTags;
      }
      case TagsQueryFetchType.FETCH_MATCHING_TAGS: {
        return fetchMatchingTags;
      }
    }
  };

  function renderCreateButton(input): React.ReactElement {
    return (
      <div className={cx('lia-tag-create')} data-testid="Add.New.Tag">
        <Icon icon={Icons.AddIcon} size={IconSize.PX_12} />
        <span className={cx('lia-tag-create-text')}>
          {formatMessage('inputCreate', {
            tag: input.toUpperCase()
          })}
        </span>
      </div>
    );
  }

  /**
   * We can work around the issue noted below by having
   * the input focus on the next-tick using a timeout.
   */
  function focusSelect(): void {
    setTimeout(() => {
      reference?.current?.focus();
    });
  }

  useEffect(() => {
    /**
     * There is an issue where when the TagSelector component is used
     * inside of an Overlay, subsequent loads do not properly focus
     * when using the `autoFocus` property of the Select component.
     * This issue manifests where the scroll of the page will jump to the top,
     * as-if the element it wants to focus is positioned near the top of the page
     * but is not yet visible or not yet positioned correctly. This same issue can
     * be seen by attempting to set focus using the programmatic API that the react-
     * select library exposes inside of `useEffect`.
     */
    focusSelect();
  });

  if (textLoading) {
    return null;
  }

  return (
    <AsyncCreatableSelect<CreatableTagOption, boolean>
      className={cx('lia-tag-select')}
      classNamePrefix="lia-react-select"
      closeMenuOnSelect
      blurInputOnSelect={false}
      components={{
        IndicatorSeparator: (): null => null,
        DropdownIndicator: (): null => null,
        NoOptionsMessage: (): null => null,
        LoadingMessage: (props): React.ReactElement => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <span className={cx('sr-only')} role="alert" {...props.innerProps}>
            {props.children}
          </span>
        ),
        Menu,
        MenuList,
        Control,
        ValueContainer,
        Option
      }}
      cacheOptions
      onChange={(value, action): void => {
        focusSelect();
        if ((value as TagOption).label.trim().length === 0) {
          return;
        }
        if (!useUserDefinedTags) {
          onBlur();
        }
        return onAdd(value, action);
      }}
      onKeyDown={({ key }): void => {
        if (key === 'Escape') {
          onBlur();
        }
      }}
      placeholder={formatMessage('inputPlaceholder')}
      loadOptions={getTagsFetchOptions()}
      onBlur={onBlur}
      onFocus={onFocus}
      createOptionPosition="first"
      formatCreateLabel={renderCreateButton}
      value={null}
      ref={reference}
      tabSelectsValue={false}
      theme={reactSelectTheme}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(!useUserDefinedTags && {
        defaultOptions: !useUserDefinedTags,
        isValidNewOption: inputValue => useUserDefinedTags && inputValue !== '',
        menuIsOpen: true
      })}
    />
  );
};

export default TagSelector;
